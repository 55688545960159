<template>
  <div v-if="benefits.length > 0" class="flex items-center md:order-last">
    <div
      class="bg-static-info-mid border-static-default-low rounded-lg flex items-center border p-8"
    >
      <IconSim />
    </div>
    <RevLink class="ml-12 text-left" @click="openModal('bouygues-assistance')">
      {{ i18n(translations.bouyguesAssistance) }}
    </RevLink>
  </div>

  <BouyguesAssistanceDrawer :benefits name="bouygues-assistance" />
</template>

<script setup lang="ts">
import type { Benefit } from '@backmarket/http-api/src/api-specs-b2c-services/mobile-plan/types/mobile-plan-offers'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevLink } from '@ds/components/Link'
import { openModal } from '@ds/components/ModalBase'
import { IconSim } from '@ds/icons/IconSim'

import BouyguesAssistanceDrawer from '~/scopes/checkout/pages/BouyguesPhonelineSetup/components/BouyguesAssistanceDrawer/BouyguesAssistanceDrawer.vue'

import translations from './BouyguesReassuranceItems.translations'

const i18n = useI18n()

defineProps<{
  benefits: Benefit[]
}>()
</script>
