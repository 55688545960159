<template>
  <RevDrawer
    :close-button-label="i18n(translations.closeButtonLabel)"
    :name
    :title="i18n(translations.drawerTitle)"
  >
    <template #body>
      <BouyguesLogo class="mb-8" />

      <div class="space-y-32 pb-[5rem]">
        <h1 class="heading-1">{{ i18n(translations.title) }}</h1>

        <div v-if="benefits.includes('FREE_CURRENT_MOBILE_PLAN_CANCELLATION')">
          <h2 class="heading-2">
            {{ i18n(translations.numberBouyguesTitle) }}
          </h2>
          <div class="body-1">
            {{ i18n(translations.bouyguesPhoneTransfer) }}
          </div>
        </div>

        <div v-if="benefits.includes('CUSTOMER_CARE_7DAY')">
          <h2 class="heading-2">{{ i18n(translations.assistanceTitle) }}</h2>
          <div class="body-1">
            {{ i18n(translations.bouyguesPortalAccess) }}
          </div>
        </div>

        <div v-if="benefits.includes('CHANGE_YOUR_MIND_14_DAYS')">
          <h2 class="heading-2">
            {{ i18n(translations.changeYourMindTitle) }}
          </h2>
          <div class="body-1">{{ i18n(translations.returnPolicy) }}</div>
        </div>

        <div v-if="benefits.includes('ONLINE_CUSTOMER_SERVICES')">
          <h2 class="heading-2">
            {{ i18n(translations.customerSpaceTitle) }}
          </h2>
          <div class="body-1">{{ i18n(translations.customerSpace) }}</div>
        </div>
      </div>
    </template>
  </RevDrawer>
</template>

<script setup lang="ts">
import type { Benefit } from '@backmarket/http-api/src/api-specs-b2c-services/mobile-plan/types/mobile-plan-offers'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevDrawer } from '@ds/components/Drawer'

import BouyguesLogo from '~/components/BouyguesLogo/BouyguesLogo.vue'

import translations from './BouyguesAssistanceDrawer.translations'

const i18n = useI18n()

defineProps<{ benefits: Benefit[]; name: string }>()
</script>
