export default {
  closeButtonLabel: {
    id: 'checkout_services_bouygues_assistance_drawer_close_button_label',
    defaultMessage: 'Close Bouygues Telecom assistance',
  },
  drawerTitle: {
    id: 'checkout_services_bouygues_bouygues_assistance_drawer_drawer_title',
    defaultMessage: 'Bouygues Telecom assistance',
  },
  title: {
    id: 'checkout_services_bouygues_bouygues_assistance_drawer_title',
    defaultMessage:
      'Bénéficiez de l’assistance Bouygues Telecom pour votre forfait mobile',
  },
  numberBouyguesTitle: {
    id: 'checkout_services_bouygues_bouygues_assistance_drawer_number_bouygues_title',
    defaultMessage: 'Pour votre numéro, Bouygues Telecom s’occupe de tout !',
  },
  bouyguesPhoneTransfer: {
    id: 'checkout_services_bouygues_bouygues_assistance_drawer_phone_transfer',
    defaultMessage:
      'Il vous suffit d’obtenir le numéro de Relevé d’Identité Opérateur (RIO) et de demander la portabilité de votre numéro. Bouygues Telecom se charge gratuitement de la résiliation de votre forfait actuel et de transférer votre ligne.',
  },
  assistanceTitle: {
    id: 'checkout_services_bouygues_bouygues_assistance_drawer_assistance_title',
    defaultMessage: 'Une assistance 7j/7 en cas de pépin',
  },
  bouyguesPortalAccess: {
    id: 'checkout_services_bouygues_bouygues_assistance_drawer_portal_access',
    defaultMessage:
      'Un assistant mobile, disponible 24h/24h pour vous aider à trouver une solution, et des conseillers à votre disposition du lundi au samedi, de 8h30 à 20h (appel gratuit depuis un mobile ou un téléphone fixe).',
  },
  changeYourMindTitle: {
    id: 'checkout_services_bouygues_bouygues_assistance_drawer_change_your_mind_title',
    defaultMessage: 'Vous changez d’avis ? Pas de soucis !',
  },
  returnPolicy: {
    id: 'checkout_services_bouygues_bouygues_assistance_drawer_return_policy',
    defaultMessage:
      'Vous disposez d’un délai de 14 jours pour nous informer de votre rétractation. Ce délai court à compter du jour de la réception de votre carte SIM.',
  },
  customerSpaceTitle: {
    id: 'checkout_services_bouygues_assistance_drawer_customer_space_title',
    defaultMessage: 'Pour toute information, un Espace Client dédié',
  },
  customerSpace: {
    id: 'checkout_services_bouygues_assistance_drawer_customer_space',
    defaultMessage:
      'Sur votre espace client Bouygues Telecom, suivez vos commandes, consultez en quelques clics vos consommations, vos factures et vos paiements, votre offre et vos options, et bien plus …',
  },
}
