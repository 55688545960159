<template>
  <div>
    <h3 class="heading-3 mb-12 md:mb-16">
      {{ i18n(translations.title) }}
    </h3>
    <RevCard class="grid grid-cols-1 gap-12 p-24 md:grid-cols-2">
      <div class="flex items-center md:order-1">
        <div
          class="bg-static-info-mid border-static-default-low rounded-lg flex items-center border p-8"
        >
          <IconQuality />
        </div>
        <RevLink class="ml-12 text-left" @click="openWarrantyModal">
          {{ i18n(translations.warranty) }}
        </RevLink>
      </div>
      <div class="flex items-center md:order-3">
        <div
          class="bg-static-info-mid border-static-default-low rounded-lg flex items-center border p-8"
        >
          <IconTruck />
        </div>
        <div class="ml-12">{{ i18n(translations.shipping) }}</div>
      </div>
      <div class="flex items-center md:order-2">
        <div
          class="bg-static-info-mid border-static-default-low rounded-lg flex items-center border p-8"
        >
          <IconRefund />
        </div>
        <RevLink class="ml-12 text-left" @click="openSatisfactionModal">
          {{ i18n(translations.return, { days: coolingOffDays }) }}
        </RevLink>
      </div>
      <div class="flex items-center md:order-4">
        <div
          class="bg-static-info-mid border-static-default-low rounded-lg flex items-center border p-8"
        >
          <IconHeadset />
        </div>
        <div class="ml-12">{{ i18n(translations.service) }}</div>
      </div>
      <slot />
      <ClientOnly>
        <SatisfactionModal :name="REASSURANCE.SATISFACTION" />
        <WarrantyModal :name="REASSURANCE.WARRANTY" />
      </ClientOnly>
    </RevCard>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { RevCard } from '@ds/components/Card'
import { RevLink } from '@ds/components/Link'
import { openModal } from '@ds/components/ModalBase'
import { IconHeadset } from '@ds/icons/IconHeadset'
import { IconQuality } from '@ds/icons/IconQuality'
import { IconRefund } from '@ds/icons/IconRefund'
import { IconTruck } from '@ds/icons/IconTruck'

import { type Features } from '~/features'

import { REASSURANCE } from '../../config/modals'
import SatisfactionModal from '../SatisfactionModal/SatisfactionModal.vue'
import WarrantyModal from '../WarrantyModal/WarrantyModal.vue'

import translations from './ReassuranceItems.translations'

const i18n = useI18n()

const { features } = useMarketplace<Features>()
const { coolingOffDays } = features

const openSatisfactionModal = () => {
  openModal(REASSURANCE.SATISFACTION)
}

const openWarrantyModal = () => {
  openModal(REASSURANCE.WARRANTY)
}
</script>
